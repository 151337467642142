import {
  Button,
  Icon,
  IconButton,
  useResponsiveValue,
} from '@monash/portal-react';
import PageContainer from '../PageContainer';
import Stack from 'components/ui/stack/Stack';
import fs from 'styles/font-styles.module.scss';
import UpdateCard from './components/UpdateCard';
import { getCurrentDate } from 'components/providers/data-provider/utils';
import { useEffect, useState, useRef } from 'react';
import { fsCollectionListener } from '@monash/portal-frontend-common';
import c from './updates.module.scss';
import { isBetweenDates } from 'utils/is-between-dates';
import useOnScreen from 'hooks/use-onscreen';
import { MOBILE_RESPONSIVE } from 'components/ui/main/Main';

const Updates = ({ selected }) => {
  const currentDate = getCurrentDate();
  const [dueSoonList, setDueSoonList] = useState([]);
  const [updatesList, setUpdatesList] = useState([]);

  const responsiveSize = useResponsiveValue(MOBILE_RESPONSIVE);
  const isMobile = responsiveSize === 'S';

  useEffect(() => {
    const getUpdates = async () => {
      fsCollectionListener('updates', (snapshot) => {
        const updatesFromFirestore = snapshot.docs.map((doc) => doc.data());
        const updatesWithoutDrafts = updatesFromFirestore.filter(
          (update) => update.status !== 'draft'
        );

        const dueSoon = updatesWithoutDrafts.filter((update) =>
          isBetweenDates(Date.now(), update.startDate, update.endDate)
        );

        const updates = updatesWithoutDrafts.filter(
          (update) =>
            !update.startDate ||
            !isBetweenDates(Date.now(), update.startDate, update.endDate)
        );

        setDueSoonList(dueSoon);
        setUpdatesList(updates);
      });
    };
    getUpdates();
  }, []);

  // -- Scroll to top functionality --
  const [showBackToTopButton, setShowBackToTopButton] = useState(false);
  const updatesColumnRef = useRef();
  const scrollDetectorRef = useRef();

  const scrollToTop = () => {
    const updatesColumn = updatesColumnRef.current;

    const scrollOptions = {
      block: 'start',
      behavior: 'smooth',
    };

    updatesColumn?.scrollIntoView(scrollOptions);
  };

  const SCROLL_DETECTION_THRESHOLD = 100; // defined here instead of css so we can programatically set it if we want

  // true if the updates column is scrolled above the screen by some threshold
  const isUpdatesAboveScreen = useOnScreen({
    ref: scrollDetectorRef,
    threshold: 0,
  });

  // Set a delay when determining whether to show the 'back to top' button.
  // Without the delay, the scrollIntoView call doesn't fully complete b/c
  // the button is disabled immediately upon crossing the disabled threshold,
  // (i.e. as soon as the first update card becomes visible on screen).
  useEffect(() => {
    const DELAY = 200;
    const updatesExist = dueSoonList?.length > 0 || updatesList?.length > 0;

    const isBackToTopShown = setTimeout(() => {
      setShowBackToTopButton(updatesExist && isUpdatesAboveScreen && selected);
    }, DELAY);

    return () => {
      clearTimeout(isBackToTopShown);
    };
  }, [dueSoonList, updatesList, isUpdatesAboveScreen, selected]);

  return (
    <PageContainer className={c.centeringGrid}>
      <div ref={updatesColumnRef} className={c.updatesColumn}>
        {Boolean(dueSoonList.length) && (
          <>
            <h2 className={`${c.heading} ${fs.md}`}>Due soon</h2>

            <Stack className={c.updatesWrapper} gap="1.6rem">
              {dueSoonList.map((update) => (
                <UpdateCard
                  key={update.title}
                  currentDate={currentDate}
                  dueDate={update.endDate}
                  isDueSoon
                  {...update}
                />
              ))}
            </Stack>
          </>
        )}

        {Boolean(updatesList.length) && (
          <>
            <h2 className={`${c.heading} ${fs.md}`}>Updates</h2>

            <Stack className={c.updatesWrapper} gap="1.6rem">
              {updatesList.map((update) => (
                <UpdateCard
                  key={update.title}
                  currentDate={currentDate}
                  dueDate={update.endDate}
                  {...update}
                />
              ))}
            </Stack>
          </>
        )}

        <div className={c.loadMoreWrapper}>
          <Button mode="canvas" variant="secondary">
            Load more updates
          </Button>
        </div>

        {/* 
          Empty div used to enable/disable the back to top button. Absolutely positioned slightly below the viewport.
          When the top of this div is within/above the viewport, the back to top button is enabled.
          When the top of this div is below the viewport, the back to top button is disabled.
        */}
        <div
          ref={scrollDetectorRef}
          className={c.scrollDetector}
          inert="true"
          style={{
            top: `calc(100dvh + ${SCROLL_DETECTION_THRESHOLD}px`,
            height: `calc(100% - (100dvh + ${SCROLL_DETECTION_THRESHOLD}px))`,
          }}
        />
      </div>

      <div className={c.backToTopButtonContainer}>
        <IconButton
          icon={Icon.ArrowUp}
          className={c.backToTopButton}
          aria-label="Back to top"
          onClick={scrollToTop}
          disabled={!showBackToTopButton}
          {...(isMobile
            ? {
                mode: 'card',
                variant: 'primary',
              }
            : {
                mode: 'canvas',
                variant: 'secondary',
              })}
        />
      </div>
    </PageContainer>
  );
};

export default Updates;
